/*REMOVE THUMBNAIL ON CAROUSEL*/
.thumbnail-controller {
  display: none;
  }

.carousel-slide .btn--wcm{
  float: left;
}

/*NEWS TEASER STYLING*/
.teaser-date{
	margin-top: 5px;
}

.front .view-news .views-more-link{
	margin-bottom: 10px;
	@include breakpoint ($lg){
		float: right !important;
	}
	@include breakpoint ($md){
		float: none;
	}
}

.front .pane-news-panel-pane-5 h3, {
  padding: 0px 0px 10px 0px;
}

.front .pane-fpid-25 h3{
  padding: 0px 0px 10px 0px;
}

@mixin news_col{
	background: #f7f7f7;
  opacity: 0.9;
	padding: 0px 20px 0px 20px;
	height: auto;
}

.front .view-news .views-row-first{
	@include news_col;
  margin: 0px; 	
}

.front .view-news .views-row-last{
	@include news_col;
  margin: 0px 0px 25px 0px; 	
}

.front .view-news .views-row-first .news-inner{
  border-bottom: solid 1px #a5aeb4;
}

/*TITLE*/
.front a.title {
  background: none repeat scroll 0 0;
  background-color: $wcm-white;
  opacity: 0.9;
  display: block;
  left: 1px;
  padding: 2px 15px;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  top: -28.5px;
  left: 0px;
  width: 100%;
}

@include breakpoint($sm){
 .tile img{
  width: 100%;
 }
}

@include breakpoint($xs){
 .tile img{
  width: 100%;
 }
}

/*ADDRESS*/
@mixin row-dim{
  width: 33.3%;
  height: 200px;
  padding: 0% 2% 1% 2%;
  margin: 5px 0px -10px 0px;
  text-align: center; 
  overflow: hidden;
  float: left;     
  }

.row-left{
  @include row-dim;
  clear: left;
}

.row-center{
@include row-dim;
  position: static; 
  border-left: 1px solid $wcm-border-gray;
  border-right: 1px solid $wcm-border-gray;
}

.row-right{
@include row-dim;
} 

/*TABLET & MOBILE*/
@media (max-width : 991px){
  .row-left,{
    width: 100%;
    height: auto;    
    margin: 15px 0px 0px 0px;
    background-color: #f7f7f7;
    border: 0px;
  }

  .row-center, {
    width: 100%;
    height: auto;    
    margin: 0px !important;
    background-color: #f7f7f7;
    border-top: 1px solid $wcm-border-gray;
    border-right: 0px;
    border-bottom: 1px solid $wcm-border-gray;
    border-left: 0px;
  }

  .row-right{
    width: 100%;
    height: auto;    
    margin: 0px 0px -20px 0px !important;
    background-color: #f7f7f7;
    border: 0px;
  }
}

/*APPOINTMENT BUTTON STYLING*/
#panels-ipe-paneid-481 .btn--wcm {
  width: 100%;
}