/*NAV OVERRIDE*/
/*Level 1 Nav*/

#main-nav {
  ul {
    li {
      &.level-1 {
        padding: 0 15px;
      }
      &.home {
        padding: 0;
      }
    }
  }
}

/*Footer Nav*/
.block-menu-block .content {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}
