/*STYLING FOR FACULTY LIST*/
.faculty-row{
    float: left;
    text-align: left;
    width: 100% !important;
    padding: 0% 5%;
}

@include breakpoint ($md){
    .faculty-row{
        float: left;
        text-align: left;
        width: 33% !important;
        height: 300px;
        padding: 5%;
        }    
}

#related-content-sidebar ul {
    padding-left: 0px !important;
    list-style: none !important;
}

.item-list ul li {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none !important;
}

/*HERO IMAGE CAPTION*/
.hero-image-full-caption{
        width: 100%;
}

.information-sidebar{
    font-size: 13px;
}

/*CLINICAL TRIALS STATUS STYLING*/
.ct_active{
    color: $wcm-bright-orange;
}

.ct_inactive{
    color: $wcm-med-gray;
}

/*STYLING FOR NEWS PAGE*/
.hidden_anchor {
  opacity: 0;
  margin-left: -20px; }